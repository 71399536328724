import { SEO } from "../seo";
import React, { useState } from "react";
import {
  Alert,
  Box,
  Card,
  CardContent,
  Grid,
  Link,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Container } from "../layouts/container";
import { NavigateNext, Send } from "@mui/icons-material";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button } from "../atoms/button";
import axios from "axios";
import { GatsbyShop } from "../utils/shop";

export default ({
  data: {
    homeJson: { first, second, third },
    allShopJson: { nodes: shops },
  },
}: PageProps<{
  homeJson: {
    first: { title: string; paragraph: string };
    second: { title: string; paragraph: string };
    third: { title: string; paragraph: string };
  };
  allShopJson: { nodes: GatsbyShop[] };
}>) => {
  const [form, setForm] = useState({ name: "", email: "", message: "" });
  const [open, setOpen] = useState(false);
  const valid =
    form.name.length > 0 && form.email.length > 0 && form.message.length > 0;
  const sendMessage = async () => {
    if (valid) {
      await axios.post("/api/contacts", form);
      setForm({ name: "", email: "", message: "" });
      setOpen(true);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <SEO
        title="Exoshop - La plateforme e-commerce des vignerons."
        description="Créez rapidement votre boutique en ligne de vente de vins en direct au départ de votre domaine."
      />
      <Box
        sx={{
          backgroundColor: "primary.main",
          color: "primary.contrastText",
        }}
      >
        <Container>
          <Grid container>
            <Grid sx={{ display: "flex", py: 2, alignItems: "center" }} item>
              <img width="44" height="44" src="/exoshop/logo.svg" />
              <Typography sx={{ ml: 2 }} variant="h1">
                Exoshop
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ pb: 4 }} spacing={4}>
            <Grid
              item
              order={{ xs: 1, md: 0 }}
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{ fontSize: { xs: 24, sm: 32, md: 36 } }}
              >
                {first.title}
              </Typography>
              <Typography variant="body1" sx={{ my: 2, fontSize: 18 }}>
                {first.paragraph}
              </Typography>
              <Link
                href="#contact"
                sx={{
                  color: "primary.contrastText",
                  fontWeight: "bold",
                  fontSize: 18,
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                Ça m'intéresse
                <NavigateNext />
              </Link>
            </Grid>
            <Grid item order={{ xs: 0, md: 1 }} xs={12} md={6}>
              <Box
                sx={{
                  pt: {
                    xs: "60%",
                    sm: "40%",
                    md: "80%",
                  },
                  height: "100%",
                  backgroundImage: "url('/exoshop/frame_lite.png')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center center",
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box py={4}>
        <Container>
          <Grid container spacing={4}>
            <Grid order={{ xs: 1, md: 0 }} item xs={12} md={6}>
              <Box
                sx={{
                  pt: {
                    xs: "60%",
                    sm: "40%",
                    md: "80%",
                  },
                  height: "100%",
                  backgroundImage: "url('/exoshop/wine.svg')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center center",
                }}
              />
            </Grid>
            <Grid
              item
              order={{ xs: 0, md: 1 }}
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{ fontSize: { xs: 24, sm: 32, md: 36 } }}
              >
                {second.title}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  my: 2,
                  fontSize: 18,
                  a: {
                    color: "primary.main",
                    fontWeight: "bold",
                    textDecoration: "none",
                  },
                }}
                dangerouslySetInnerHTML={{ __html: second.paragraph }}
              />
              <Link
                href="#contact"
                sx={{
                  color: "primary.main",
                  fontWeight: "bold",
                  fontSize: 18,
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                Rejoignez nous
                <NavigateNext />
              </Link>
            </Grid>
            <Grid
              item
              order={2}
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{ fontSize: { xs: 24, sm: 32, md: 36 } }}
              >
                {third.title}
              </Typography>
              <Typography variant="body1" sx={{ my: 2, fontSize: 18 }}>
                {third.paragraph}
              </Typography>
            </Grid>
            <Grid item order={3} xs={12} md={6}>
              <Box
                sx={{
                  pt: {
                    xs: "60%",
                    sm: "40%",
                    md: "80%",
                  },
                  height: "100%",
                  backgroundImage: "url('/exoshop/eshop.svg')",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "center center",
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box id="contact" sx={{ py: 8, backgroundColor: "grey.200" }}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={8} md={6}>
              <Card
                elevation={0}
                sx={{
                  boxShadow:
                    "0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CardContent>
                  <Stack spacing={2}>
                    <Typography
                      variant="h2"
                      sx={{ fontSize: { xs: 24, sm: 32, md: 36 } }}
                    >
                      Contactez-nous
                    </Typography>
                    <TextField
                      name="name"
                      type="text"
                      label="Votre nom"
                      value={form.name}
                      onChange={(e) =>
                        setForm({ ...form, name: e.target.value })
                      }
                    />
                    <TextField
                      name="email"
                      type="email"
                      label="Votre email"
                      value={form.email}
                      onChange={(e) =>
                        setForm({ ...form, email: e.target.value })
                      }
                    />
                    <TextField
                      name="message"
                      multiline
                      rows={3}
                      label="Votre message"
                      value={form.message}
                      onChange={(e) =>
                        setForm({ ...form, message: e.target.value })
                      }
                    />
                    <Button onClick={() => sendMessage()} disabled={!valid}>
                      <Typography
                        component="span"
                        sx={{ display: "inline-flex", alignItems: "center" }}
                      >
                        <Send fontSize="small" sx={{ mr: 0.5 }} />
                        <Typography component="span">Envoyer</Typography>
                      </Typography>
                    </Button>
                    <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      open={open}
                      autoHideDuration={6000}
                      onClose={() => setOpen(false)}
                    >
                      <Alert variant="filled" severity="success">
                        Votre message a été envoyé !
                      </Alert>
                    </Snackbar>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: "primary.main",
          color: "primary.contrastText",
          py: 4,
        }}
      >
        <Container>
          <Typography
            variant="h2"
            sx={{ fontSize: { xs: 24, sm: 32, md: 36 }, mb: 4 }}
          >
            Boutiques à la une
          </Typography>
          <Grid container spacing={3}>
            {shops.map((shop) => (
              <Grid key={shop.id} xs={12} sm={6} md={4} item>
                <Link
                  rel="noopener"
                  target="_blank"
                  href={`https://${
                    shop.customDomain ? shop.domain : `exoshop.app/${shop.id}`
                  }`}
                  sx={{
                    textDecoration: "none!important",
                    display: "block",
                    height: "100%",
                  }}
                >
                  <Card
                    elevation={0}
                    sx={{
                      position: "relative",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      ".banner": {
                        transition: "transform 1s",
                      },
                      ":hover": {
                        boxShadow:
                          "0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.1)",
                        ".banner": {
                          transform: "scale(1.05)",
                        },
                      },
                    }}
                  >
                    <Box
                      sx={{
                        height: "12rem",
                        overflow: "hidden",
                        ".gatsby-image-wrapper": {
                          display: "block",
                          height: "12rem",
                        },
                        img: {
                          borderTopLeftRadius: "0.25rem",
                          borderTopRightRadius: "0.25rem",
                        },
                      }}
                    >
                      {shop.banner?.childImageSharp && (
                        <GatsbyImage
                          className="banner"
                          image={shop.banner?.childImageSharp?.gatsbyImageData}
                          alt={shop.name}
                        />
                      )}
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        left: "1rem",
                        top: "10.125rem",
                        p: "0.5rem",
                        backgroundColor: "common.white",
                        display: "inline-block",
                        borderRadius: "0.25rem",
                        boxShadow: "0px 1px 8px rgb(0 0 0 / 20%)",
                      }}
                    >
                      {shop.logo?.childImageSharp && (
                        <GatsbyImage
                          image={shop.logo?.childImageSharp?.gatsbyImageData}
                          alt="logo"
                        />
                      )}
                    </Box>
                    <Box sx={{ p: 1.5 }}>
                      <Typography variant="h3" sx={{ pt: 3 }}>
                        {shop.name}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ p: 1.5, backgroundColor: "grey.100", flexGrow: 1 }}
                    >
                      <Typography variant="body2">
                        <strong>Appelations :</strong>{" "}
                        {[
                          ...new Set(shop.products.map(({ title }) => title)),
                        ].join(", ")}
                      </Typography>
                    </Box>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Box sx={{ py: 1 }}>
        <Container>
          <Typography sx={{ textAlign: "center" }}>
            La solution Exoshop est conçue et fabriquée avec ♥ par{" "}
            <Link rel="noopener" href="https://naonedit.fr" target="_blank">
              NAONED IT
            </Link>
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export const query = graphql`
  query {
    homeJson {
      first {
        title
        paragraph
      }
      second {
        title
        paragraph
      }
      third {
        title
        paragraph
      }
    }
    allShopJson(limit: 3, filter: { show: { eq: true } }) {
      nodes {
        id
        name
        domain
        customDomain
        logo {
          childImageSharp {
            gatsbyImageData(
              width: 44
              height: 44
              layout: FIXED
              placeholder: BLURRED
            )
          }
        }
        banner {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        products {
          title
        }
      }
    }
  }
`;
